@import "../../../styles/config";

.container {
  background-color: #ffffff;
  font-family: Lato;
}

.bottom-jaw {
  border-top: 1px solid #e5eaef;
  height: $grid-unit-80;
}

.empty-view {
  padding: 120px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty-title {
  @include heading-4;
  color: $black;
  margin-top: $grid-unit-30;
}
