@import "../../../styles/config";

$kebab-width: $grid-unit-20;
$kebab-width-mobile: $grid-unit-30;

.container {
  padding: $grid-unit-25 $grid-unit-25;
  display: flex;
  border-bottom: 1px solid #e5eaef;

  &:last-of-type {
    border-bottom: none;
  }
}

.time-start,
.time-end {
  font-size: 14px;
  min-width: 60px;
}

.time-end {
  color: $wolf-600;
}

.time-start {
  color: $wolf-800;
  margin-bottom: $grid-unit-10;
}

.type-color {
  width: 7px;
  margin: 0px $grid-unit-25;
  border-radius: $grid-unit-15;
}

.entry-user {
  color: $wolf-800;
}

.entry-user {
  margin-bottom: $grid-unit-10;
}

.entry-staff {
  margin-bottom: $grid-unit-10;
}

.entry-user,
.entry-event,
.entry-staff {
  font-size: 14px;
}

.entry-staff,
.entry-event {
  color: $wolf-600;
}

.entry-spacer {
  flex-grow: 1;
}

.kebab {
  width: $kebab-width;

  svg {
    @include icon-size($grid-unit-30);
    color: $black;

    @media (max-width: $breakpoint-mobile) {
      @include icon-size($grid-unit-20);
    }
  }

  @media (max-width: $breakpoint-mobile) {
    width: $kebab-width-mobile;
  }
}

.entry-chip {
  margin: 0px $grid-unit-25;
}
