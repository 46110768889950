@import "../../../styles/config";

$border-color: #e5eaef;
$loading-width: 400px;

.wrap {
  background: #fff;
  position: relative;

  // remove height
  :global(.fc-media-screen) {
    height: initial !important;
  }
  :global(.fc-view-harness) {
    height: initial !important;
  }
  :global(.fc-view) {
    height: initial !important;
    position: relative !important;
  }

  // remove scroll
  :global(.fc-scroller-liquid-absolute) {
    position: relative !important;
  }
  :global(.fc-timeGridWeek-view) {
    position: relative !important;
  }
  :global(.fc-scroller) {
    overflow: initial !important;
  }

  // header
  :global(.fc-col-header-cell-cushion) {
    padding: 8px;
    text-align: center;
  }

  // remove header link underline
  :global(.fc-col-header-cell-cushion:not([data-navlink])) {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  // time slot
  :global(.fc-timegrid-axis-cushion) {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #60717d;
  }
  :global(.fc-timegrid-slot-label-cushion) {
    font-family: Lato;
    font-size: 12px;
    font-weight: 400;
    color: #60717d;
  }

  // event
  :global(.fc-event) {
    border-radius: 4px;
    overflow: hidden;
  }

  // hide message
  :global(.fc-license-message) {
    display: none;
  }

  // hide time divider
  :global(.fc-timegrid-divider) {
    display: none;
  }

  // hide slot internal borders
  :global(.fc-timegrid-slot-minor) {
    border-top-width: 0;
  }

  // slot selection
  :global(.fc-highlight) {
    background: #60717d;
    opacity: 0.08;
  }

  // now indicator
  :global(.fc-timegrid-now-indicator-line) {
    border-width: 0 0 2px 0;
    &::before {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      width: 8px;
      height: 8px;
      background: var(--fc-now-indicator-color);
      border-radius: 999px;
    }
  }

  // remove today background
  :global(.fc-day-today) {
    background-color: initial !important;
  }

  // hide the now arrow
  :global(.fc-timegrid-now-indicator-arrow) {
    display: none;
  }

  // padding to month cells
  :global(.fc-daygrid-day-events) {
    padding-bottom: 8px;
  }

  // row height
  :global(.fc-timegrid-slot) {
    position: relative;
    line-height: 60px;
  }

  // day
  :global(.fc-daygrid-day-top) {
    justify-content: center;
  }
  :global(.fc-daygrid-day-number) {
    display: inline-block;
    padding: 12px;
    font-family: Lato;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: center;
    color: #2a3540;
    text-transform: uppercase;
  }

  :global(.fc-day) {
    border-color: $border-color;
  }

  :global(.fc-day-today) {
    background: none;
  }

  // list day
  :global(.fc-list-day th) {
    z-index: $calendar-z-index;
  }

  // list day text
  :global(.fc-list-day-text) {
    font-family: Lato;
    color: $wolf-800;
    font-size: 14px;
  }

  // list day side text
  :global(.fc-list-day-side-text) {
    font-family: Lato;
    color: $wolf-800;
    font-size: 14px;
  }

  // list event time
  :global(.fc-list-event-time) {
    font-family: Lato;
    color: $wolf-800;
    font-size: 14px;
  }

  // list day header
  :global(.fc-list-day-cushion) {
    padding: 12px 24px;
  }

  // blocked slot (background event)
  :global(.fc-bg-event) {
    background: $wolf-200;
    pointer-events: none;
  }

  // show the now indicator above cell lines
  :global(.fc-timegrid-now-indicator-container) {
    right: -1px;
  }

  // month
  &.month {
    :global(.fc-event) {
      cursor: pointer;
      box-shadow: none;
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }

    // remove month day link underline
    :global(.fc-daygrid-day-number) {
      text-decoration: none !important;
      &:hover {
        text-decoration: none !important;
      }
    }

    // make day clickable
    &.dayClickable {
      :global(.fc-daygrid-day-number) {
        > div {
          cursor: pointer;
          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }

  // resource
  &.resource {
    // keep the red dot only in the first column
    :global(.fc-timegrid-now-indicator-line) {
      border-width: 0 0 2px 0;
      &::before {
        content: none;
      }
    }
    :global(.fc-timegrid-body td:nth-child(2) .fc-timegrid-now-indicator-line) {
      &::before {
        content: "";
      }
    }
  }

  // list
  &.list {
    // remove the dot and item padding when having a custom event render
    &.hasRenderEvent {
      :global(.fc-list-event-graphic) {
        display: none;
      }
      :global(.fc-list-event-title) {
        padding: 0;
      }
    }

    // show pointer cursor on hover
    :global(.fc-event) {
      cursor: pointer;
    }
  }

  // sticky headers
  &.stickyHeaders {
    :global(.fc-scrollgrid th) {
      position: sticky;
      top: 0;
      background: #fff;
      z-index: $calendar-z-index;
    }
  }

  // up left cell border
  &.stickyHeaders {
    :global(.fc-scrollgrid th.fc-timegrid-axis:first-child) {
      border-bottom: 1px solid $border-color;
    }
  }

  // sticky time column
  &.stickyTimeColumn {
    :global(.fc-scrollgrid-shrink) {
      background: #fff;
    }

    :global(.fc-timegrid-axis) {
      pointer-events: none;
      position: sticky;
      left: 0;
      z-index: $calendar-z-index;
      border-color: transparent;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 1px;
        background: $border-color;
      }
    }

    :global(.fc-timegrid-slot:first-child) {
      position: sticky;
      left: 0;
      background: #fff;
      z-index: $calendar-z-index;
      background: #fff;
      &::before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        height: 1px;
        width: 100%;
        background: $border-color;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        height: 100%;
        width: 1px;
        background: $border-color;
      }
    }

    :global(.fc-timegrid-slot-minor) {
      &::before {
        content: none !important;
      }
    }

    :global(.fc-scroller-harness) {
      overflow: initial !important;
    }

    :global(.fc-timegrid-slots) {
      z-index: initial !important;
    }

    :global(.fc-timegrid-body tr:first-child .fc-timegrid-slot) {
      &::before {
        content: none !important;
      }
    }
  }

  // with event click
  &.eventClickable {
    :global(.fc-event:not(.fc-bg-event)) {
      cursor: pointer;
    }
  }
}

.dayHeader {
  &.today {
    width: 20px;
    height: 20px;
    line-height: 18px;
    margin: auto;
    background: #e6f4ff;
    border-radius: 999px;
    color: #0063ff;
  }
}

.slotLabel {
  position: absolute;
  top: 2px;
  right: 4px;
  line-height: 18px;
}

.weekHeader {
  position: relative;

  &.today {
    .weekHeaderTop {
      color: $pacific-primary-600 !important;
    }
    .weekHeaderBottom {
      background-color: $pacific-primary-100;
      color: $pacific-primary-600 !important;
    }
  }
}

.weekHeaderBottom {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 9999px;
}

.resourceHeaderRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// loading
.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  overflow: hidden;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(
      90deg,
      transparent,
      $pacific-primary-600,
      transparent
    );
    height: 2px;
    width: $loading-width;
    z-index: $calendar-z-index;
    animation-name: loading;
    animation-duration: 1000ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}

@keyframes loading {
  0% {
    left: -$loading-width;
  }
  100% {
    left: calc(100% + $loading-width);
  }
}
