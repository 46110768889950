@import "../../../../styles/config";

.blockedSlotsContainer {
  @include reset;
  min-width: 122px;
  position: relative;

  &.allDay {
    height: 22px;

    .eventSlotsContainer {
      height: 22px;
    }
  }

  &.flex {
    width: 100%;
  }
}

.hotspot {
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  border-radius: 4px;
  cursor: pointer;

  &.over {
    background-color: $pacific-primary-100;
  }
}

.eventSlotsContainer {
  @include reset;
  position: relative;
  min-width: 122px;
  position: relative;
  border-bottom: 1px solid #c4ced8;

  &.flex {
    width: 100%;
  }
}

.hourBar {
  height: 2px;
  position: absolute;
  background-color: $wild-watermellon-600;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;

  .hourBall {
    background-color: $wild-watermellon-600;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: -6px;
    top: -12px;
    position: absolute;
  }
}
