@import "../../../../../styles/config";

/* base column header styles */
.container {
  @include reset;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 123px; /* (122px) cell width + 1px column right-border */
  flex-shrink: 0;
  width: 100%;

  &.flex {
    flex: 1;
  }

  .title {
    margin-top: revert;
    font-family: Lato;
    text-transform: uppercase;
    font-size: 14px;
    color: $wolf-800;
  }

  .icon {
    @include reset;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $wolf-800;
    text-align: center;
  }

  .skeleton {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }

  &.active {
    p,
    h3 {
      color: $pacific-primary-600;
    }

    .icon {
      color: $pacific-primary-600;
      background-color: $pacific-primary-100;
    }
  }

  &.inactive {
    p,
    h3 {
      color: $wolf-500;
    }
  }

  &.loading {
    p {
      opacity: 0;
    }
  }
}

/* Custom column header icon classes */
.staffIcon {
  @include reset;
  background-color: $pacific-primary-100;
  color: $pacific-primary-600;
  font-weight: normal;
  font-size: 18px;
}

.dateIcon {
  @include reset;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
