@import "../../../../styles/config";

.popup-wrapper {
  width: calc(100% - 16px);
  position: absolute;

  .event-slot {
    width: 100%;
    cursor: pointer;
  }
}

.event-slot {
  @include reset;
  padding: 4px 6px;
  width: calc(100% - 8px);
  background-color: #738cb2;
  border-radius: 4px;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  transition: all 120ms ease-in-out;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4);
  cursor: pointer;

  p {
    white-space: nowrap;
  }

  &.all-day {
    padding: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 6px;
    padding-right: 6px;

    p {
      white-space: nowrap;
      overflow: hide;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.cellAnimation {
    &:hover {
      width: 100% !important;
      left: 0 !important;
      z-index: 100;
      box-shadow: 0 0px 8px rgba(0, 0, 0, 0.25);
    }
  }
}

.group {
  display: flex;
  align-items: center;
  gap: 4px;
}

.time {
  @include reset;
  font-family: Lato;
  font-size: 10px;
  color: #fff;
  line-height: 1;
}

.title {
  @include reset;
  line-height: 1;
  font-family: Lato;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  margin-top: 4px;
}
