@import "../../../../styles/config";

.column {
  @include reset;
  flex: 1;
  border-right: 1px solid #c4ced8;
  height: max-content;
  display: flex;
  flex-direction: column;
  position: relative;

  &.flex {
    width: 100%;
  }
}
