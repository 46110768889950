@import "../../../../styles/config";

.tickCell {
  @include reset;
  position: relative;
  width: 64px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-right: 1px solid #c4ced8;
  &.start {
    justify-content: space-between;
  }

  &.allDay {
    height: 22px;
  }
}

.tickRow {
  @include reset;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  &.start {
    align-items: flex-start;
    transform: translateY(-1px);
  }
}

.tickLine {
  @include reset;
  position: absolute;
  margin-left: 11px;
  margin-top: -1px;
  width: 16px;
  height: 1px;
  background-color: #dae0e6;
  top: 0;
}

.tickLabel {
  @include reset;
  font-family: Lato;
  font-size: 10px;
  height: 14px;
  line-height: 0;
  margin-bottom: -12px;
  color: $wolf-600;
  margin-right: 20px;

  &.start {
    height: 6px;
    margin-top: 6px;
  }
  &.end {
    height: 8px;
    margin-bottom: 0px;
  }
}

.tickSkeleton {
  @include reset;
  height: 14px;
  margin-bottom: -12px;
  color: $wolf-600;
  margin-right: 20px;

  &.start {
    height: 6px;
    margin-top: 6px;
  }
  &.end {
    height: 8px;
    margin-bottom: 0px;
  }
}
