@import "../../../styles/config";

.container {
  padding: $grid-unit-20 $grid-unit-25;
  font-size: 20px;
  border-top: 1px solid #e5eaef;
  background-color: #ffffff;
  font-family: Lato;
  border-bottom: 1px solid #e5eaef;

  &:last-of-type {
    border-bottom: none;
  }
}
