@import "../../../styles/config";

.root {
  font-family: Lato;

  // control
  :global(.pp__control) {
    border: 1px solid $border;
    box-sizing: border-box;
    transition: none;
    &:hover {
      border-color: $pacific-primary-300;
    }
    &:focus-within {
      box-shadow: 0 0 0 3px $pacific-primary-100;
      border-color: $pacific-primary-600;
    }
  }

  // placeholder
  :global(.pp__placeholder) {
    color: $wolf-500;
  }

  // indicator separator
  :global(.pp__indicator-separator) {
    background: $border;
    & + :global(.pp__indicators) {
      svg {
        fill: $wolf-800;
      }
    }
  }

  // indicator
  :global(.pp__indicators) {
    svg {
      width: 16px;
      height: 16px;
      fill: $wolf-500;
    }
  }

  // multi value
  :global(.pp__multi-value) {
    background: $wolf-200;
  }

  // input
  :global(.pp__input) {
    &::placeholder {
      color: transparent;
    }
  }

  // dropdown icon
  :global(.pp__dropdown-indicator) {
    * {
      fill: $wolf-800;
    }
  }

  // clear icon
  :global(.pp__clear-indicator) {
    * {
      fill: $wolf-800;
    }
  }

  // rounded
  &.rounded {
    :global(.pp__control) {
      border-radius: 99999px;
    }
  }

  // isSearchable
  &.isSearchable {
    :global(.pp__input) {
      opacity: 1 !important;
    }
    :global(.pp__control--menu-is-open) {
      :global(.pp__single-value) {
        color: $wolf-500 !important;
      }
    }
  }

  // disabled
  &.disabled {
    :global(.pp__control) {
      background-color: $wolf-100;
      border-color: $border-disabled;
    }
    :global(.pp__indicators) {
      svg * {
        fill: $wolf-400;
      }
    }
  }

  // no radius left
  &.noRadiusLeft {
    :global(.pp__control) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // no radius right
  &.noRadiusRight {
    :global(.pp__control) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  // error
  &.error > :global(.pp__control) {
    border-color: $wild-watermellon-300;
    &:hover {
      box-shadow: 0 0 0 3px rgba($wild-watermellon-600, 0.15);
    }
    &:focus-within {
      box-shadow: 0 0 0 3px rgba($wild-watermellon-600, 0.15);
    }
    &.disabled {
      &:hover {
        box-shadow: none;
      }
    }
  }

  // size
  &.small {
    @include font-size-body-sm;
    :global(.pp__control) {
      min-height: 24px;
    }
    :global(.pp__value-container) {
      padding: 0 10px;
    }
    :global(.pp__input-container) {
      margin-top: 0;
      margin-bottom: 0;
    }
    :global(.pp__multi-value) {
      margin-top: 0;
      margin-bottom: 0;
      > :first-child {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    :global(.pp__indicators) {
      height: 22px;
    }
    &.multi {
      :global(.pp__value-container) {
        padding: 0 2px;
      }
    }
  }
  &.medium {
    @include font-size-body-lg;
    :global(.pp__control) {
      min-height: 32px;
    }
    :global(.pp__value-container) {
      padding: 1px 10px;
    }
    :global(.pp__indicators) {
      height: 30px;
    }
    &.multi {
      :global(.pp__value-container) {
        padding: 0 2px;
        :global(.pp__multi-value) {
          > :first-child {
            padding-top: 2px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
  &.large {
    @include font-size-heading-5;
    :global(.pp__control) {
      min-height: 36px;
    }
    :global(.pp__value-container) {
      padding: 1px 10px;
    }
    :global(.pp__indicators) {
      height: 34px;
    }
    &.multi {
      :global(.pp__value-container) {
        padding: 0 2px;
        :global(.pp__multi-value) {
          > :first-child {
            padding-top: 2px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
  &.jumbo {
    @include font-size-heading-5;
    :global(.pp__control) {
      min-height: 48px;
    }
    :global(.pp__value-container) {
      padding: 1px 10px;
    }
    :global(.pp__indicators) {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    &.multi {
      :global(.pp__value-container) {
        padding: 0 8px;
      }
    }
  }
}

// custom option
.option {
  display: flex !important;
  align-items: center;
  gap: 8px;
  position: relative;
  font-family: Lato;
  border: 1px solid transparent;
  &:global(.pp__option--is-selected) {
    background-color: $pacific-primary-100 !important;
    color: inherit;
  }
  &:global(.pp__option--is-focused) {
    background-color: $wolf-100 !important;
  }

  svg {
    color: $wolf-700;
  }

  .sampleColor {
    margin-left: 2px;
  }

  // size
  &.small {
    @include font-size-body-sm;
    padding: 4px 10px;
    svg {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
    }
  }
  &.medium {
    @include font-size-body-lg;
    padding: 4px 10px;
    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin: 4px;
    }
  }
  &.large {
    @include font-size-heading-5;
    padding: 6px 10px;
    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin: 4px;
    }
  }
  &.jumbo {
    @include font-size-heading-5;
    padding: 8px 10px;
    svg {
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
    }
  }
  &.multi {
    padding-right: 40px;
    &:global(.pp__option--is-selected) {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 8px;
        width: 16px;
        height: 8px;
        margin-top: -7px;
        border-bottom: 2px solid $pacific-primary-600;
        border-left: 2px solid $pacific-primary-600;
        transform: rotateZ(-45deg) scale(0.7);
      }
    }
  }
}

.singleValue {
  display: flex;
  align-items: center;
  gap: 8px;

  &.small {
    svg {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
    }
    &:has(svg) + .input {
      padding-left: 20px;
    }
    &:has(.sampleColor) + .input {
      padding-left: 24px;
    }
  }
  &.medium {
    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
    &:has(svg) + .input {
      padding-left: 24px;
    }
    &:has(.sampleColor) + .input {
      padding-left: 24px;
    }
  }
  &.large {
    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
    }
    &:has(svg) + .input {
      padding-left: 24px;
    }
    &:has(.sampleColor) + .input {
      padding-left: 24px;
    }
  }
  &.jumbo {
    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    }
    &:has(svg) + .input {
      padding-left: 28px;
    }
    &:has(.sampleColor) + .input {
      padding-left: 24px;
    }
  }
}

.sampleColor {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}
