.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 123px;
}

.spacing {
  margin-top: 20px;
}
