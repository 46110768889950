@import "../../../../styles/config";

$calendar-min-height: 600px;
$calendar-max-height: 70vh;

.calendarContainer {
  display: flex;
  overflow: hidden;
}

.timeContainer {
  overflow-y: scroll;
  min-height: $calendar-min-height;
  max-height: $calendar-max-height;
  min-width: 64px;

  &::-webkit-scrollbar {
    display: none;
  }

  padding-bottom: 11px;
}

.calendarContentContainer {
  overflow: hidden;
  width: 100%;
}

#timeGrid {
  @include reset;
  min-height: $calendar-min-height;
  max-height: $calendar-max-height;
  display: flex;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d6d6d6;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #afafaf;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

#columns {
  @include reset;
  flex: 1;
  height: max-content;
  display: flex;
  z-index: 0;
}

#headerScroll {
  @include reset;
  display: flex;
  width: 100%;
  margin-left: 0;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 10px;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
