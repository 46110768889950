@import "../../../styles/config";

.container {
  position: relative;
  width: 100%;
  height: 18px;
  background: #d9d9d9;
  border-radius: $grid-unit-10;
  overflow: hidden;

  .content {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    padding-left: $grid-unit-20;
    height: 100%;
    transition: all 200ms;

    span {
      font-family: "Lato";
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      opacity: 0.6;
    }
  }
}
