@import "../../../styles/config";

.btn {
  position: relative;
  @include body-lg;
  width: fit-content;
  border-radius: 40px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 120ms ease-in-out;
  white-space: nowrap;
  &[href] {
    text-decoration: none;
  }

  &:not(:disabled):hover {
    transition: opacity 120ms ease-in-out;
    opacity: 0.75;
  }

  &:hover {
    box-shadow: 0 0 2px 4px rgba($pacific-primary-600, 0.2);
    text-decoration: none;
  }

  &:focus {
    box-shadow: 0 0 2px 4px $pacific-primary-100;
    border: 1px solid #fff;
  }

  span {
    display: block;
    order: 1;
  }

  .icon {
    display: block;

    &.icon-left {
      order: 0;
    }

    &.icon-right {
      order: 2;
    }
  }

  /* Sizes */
  &.jumbo {
    @include font-size-heading-4;
    padding: $grid-unit-15 $grid-unit-30;

    .icon {
      @include icon-size(24px);

      &.icon-left {
        margin-right: $grid-unit-15;
      }

      &.icon-right {
        margin-left: $grid-unit-15;
      }
    }
  }

  &.large {
    @include font-size-heading-5;
    padding: $grid-unit $grid-unit-25;
  }

  &.medium {
    @include font-size-body-lg;
    padding: $grid-unit-075 $grid-unit-20;
  }

  &.small {
    @include font-size-body-sm;
    padding: $grid-unit-05 $grid-unit-15;
  }

  &.large,
  &.medium,
  &.small {
    .icon {
      @include icon-size(16px);

      &.icon-left {
        margin-right: $grid-unit;
      }

      &.icon-right {
        margin-left: $grid-unit;
      }
    }
  }

  /* Types */

  &.primary {
    background-color: $brand;
    color: white;
    border: 1px solid $brand;
    &:focus {
      box-shadow: 0 0 2px 4px $pacific-primary-200;
    }

    &:not(.loading):disabled {
      pointer-events: none;
      background-color: $disabled;
      border-color: $disabled;
      color: $white;
      .icon {
        color: $white;
      }
    }

    .icon {
      color: $white;
    }
  }

  &.secondary {
    background-color: $white;
    color: $brand;
    border: 1px solid $brand;

    &:not(.loading):disabled {
      pointer-events: none;
      background-color: $white;
      border-color: $disabled;
      color: $disabled;

      .icon {
        color: $disabled;
      }
    }

    .icon {
      color: $brand;
    }
  }

  &.destructive {
    background-color: $wild-watermellon;
    color: white;
    border: 1px solid $wild-watermellon;
    &:focus {
      box-shadow: 0 0 2px 4px $wild-watermellon-200;
    }

    &:not(.loading):disabled {
      pointer-events: none;
      background-color: $disabled;
      border-color: $disabled;
      color: $white;
      .icon {
        color: $white;
      }
    }

    .icon {
      color: $white;
    }
  }

  &.text {
    color: $brand;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border: none;

    &:not(.loading):disabled {
      pointer-events: none;
      color: $disabled;
      .icon {
        color: $disabled;
      }
    }

    &:hover {
      box-shadow: none;
      text-decoration: none;
      opacity: 0.8;
    }

    .icon {
      color: $brand;
    }
  }

  /* States */

  &.loading {
    pointer-events: none;
    color: transparent;

    .icon {
      opacity: 0;
    }
  }

  /* Width */

  &.full-width {
    width: 100%;
  }

  @media (max-width: $breakpoint-mobile) {
    &.jumbo {
      @include font-size-heading-5;

      .icon {
        @include icon-size(20px);

        &.icon-left {
          margin-right: $grid-unit;
        }

        &.icon-right {
          margin-left: $grid-unit;
        }
      }
    }

    &.large {
      @include font-size-body-lg;
      padding: $grid-unit $grid-unit-20;
    }

    &.medium {
      @include font-size-body-md;
      padding: $grid-unit-05 $grid-unit-15;
    }

    &.medium,
    &.small {
      .icon {
        @include icon-size(12px);

        &.icon-left {
          margin-right: $grid-unit-05;
        }

        &.icon-right {
          margin-left: $grid-unit-05;
        }
      }
    }
  }

  .loader {
    position: absolute;
  }

  &.hideTextOnMobile {
    @media (max-width: $breakpoint-sm) {
      padding: 4px;
      span {
        display: none;
      }
      .icon {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }
}
