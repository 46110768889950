@import "../../../../styles/config";

$arrow-size: 8px;

.root {
  position: relative;
  height: 200px;
  line-height: normal;
  border-right: 1px solid #c4ced8;
  border-bottom: 1px solid #c4ced8;
}

.event {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #738cb2;
  border: 1px solid #fff;
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
  transition: all 120ms ease-in-out;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &.hasMoreBefore {
    clip-path: polygon(
      0 50%,
      $arrow-size 0,
      100% 0,
      100% 100%,
      $arrow-size 100%
    );
  }

  &.hasMoreAfter {
    clip-path: polygon(
      0 0,
      calc(100% - $arrow-size) 0,
      100% 50%,
      calc(100% - $arrow-size) 100%,
      0 100%
    );
  }

  &.hasMoreBefore.hasMoreAfter {
    clip-path: polygon(
      0 50%,
      $arrow-size 0,
      calc(100% - $arrow-size) 0,
      100% 50%,
      calc(100% - $arrow-size) 100%,
      $arrow-size 100%
    );
  }
}
