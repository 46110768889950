$colors: "wolf", "pacific-primary", "inch-worm", "wild-watermellon",
  "golden-rod", "sea-foam";
$tones: "1000", "900", "800", "700", "600", "500", "400", "300", "200", "100";

/* --------------------- */
/* Primary Color Palette */
/* --------------------- */

$pacific-primary-1000-default: #001a66;
$pacific-primary-900-default: #00288c;
$pacific-primary-800-default: #0039b3;
$pacific-primary-700-default: #004cd9;
$pacific-primary-600-default: #0063ff;
$pacific-primary-500-default: #2982ff;
$pacific-primary-400-default: #52a0ff;
$pacific-primary-300-default: #7abaff;
$pacific-primary-200-default: #a3d3ff;
$pacific-primary-100-default: #e6f4ff;

$pacific-primary-1000: var(
  --pacific-primary-1000,
  $pacific-primary-1000-default
);
$pacific-primary-900: var(--pacific-primary-900, $pacific-primary-900-default);
$pacific-primary-800: var(--pacific-primary-800, $pacific-primary-800-default);
$pacific-primary-700: var(--pacific-primary-700, $pacific-primary-700-default);
$pacific-primary-600: var(--pacific-primary-600, $pacific-primary-600-default);
$pacific-primary-500: var(--pacific-primary-500, $pacific-primary-500-default);
$pacific-primary-400: var(--pacific-primary-400, $pacific-primary-400-default);
$pacific-primary-300: var(--pacific-primary-300, $pacific-primary-300-default);
$pacific-primary-200: var(--pacific-primary-200, $pacific-primary-200-default);
$pacific-primary-100: var(--pacific-primary-100, $pacific-primary-100-default);
$pacific-primary: var(--pacific-primary-600, $pacific-primary-600-default);

/* --------------------- */
/* Netural Color Palette */
/* --------------------- */

$black-default: #000;
$wolf-900-default: #1d2329;
$wolf-800-default: #2a3540;
$wolf-700-default: #3f4b57;
$wolf-600-default: #60717d;
$wolf-500-default: #a3b1bd;
$wolf-400-default: #cad2da;
$wolf-300-default: #dae0e6;
$wolf-200-default: #e5eaef;
$wolf-100-default: #f4f7fa;
$white-default: #fff;

$black: var(--black, $black-default);
$wolf-900: var(--wolf-900, $wolf-900-default);
$wolf-800: var(--wolf-800, $wolf-800-default);
$wolf-700: var(--wolf-700, $wolf-700-default);
$wolf-600: var(--wolf-600, $wolf-600-default);
$wolf-500: var(--wolf-500, $wolf-500-default);
$wolf-400: var(--wolf-400, $wolf-400-default);
$wolf-300: var(--wolf-300, $wolf-300-default);
$wolf-200: var(--wolf-200, $wolf-200-default);
$wolf-100: var(--wolf-100, $wolf-100-default);
$white: var(--white, $white-default);

/* ------------------------ */
/* Functional Color Palette */
/* ------------------------ */

$inch-worm-1000-default: #002e18;
$inch-worm-900-default: #005429;
$inch-worm-800-default: #047a39;
$inch-worm-700-default: #0ba149;
$inch-worm-600-default: #19c85d;
$inch-worm-500-default: #3dd472;
$inch-worm-400-default: #3dd472;
$inch-worm-300-default: #8eeda9;
$inch-worm-200-default: #c0facf;
$inch-worm-100-default: #c0facf;

$inch-worm-1000: var(--inch-worm-1000, $inch-worm-1000-default);
$inch-worm-900: var(--inch-worm-900, $inch-worm-900-default);
$inch-worm-800: var(--inch-worm-800, $inch-worm-800-default);
$inch-worm-700: var(--inch-worm-700, $inch-worm-700-default);
$inch-worm-600: var(--inch-worm-600, $inch-worm-600-default);
$inch-worm-500: var(--inch-worm-500, $inch-worm-500-default);
$inch-worm-400: var(--inch-worm-400, $inch-worm-400-default);
$inch-worm-300: var(--inch-worm-300, $inch-worm-300-default);
$inch-worm-200: var(--inch-worm-200, $inch-worm-200-default);
$inch-worm-100: var(--inch-worm-100, $inch-worm-100-default);
$inch-worm: var(--inch-worm-600, $inch-worm-600-default);

$wild-watermellon-1000-default: #5c0011;
$wild-watermellon-900-default: #820014;
$wild-watermellon-800-default: #a8071a;
$wild-watermellon-700-default: #cf1322;
$wild-watermellon-600-default: #f5222d;
$wild-watermellon-500-default: #ff4d4f;
$wild-watermellon-400-default: #ff7875;
$wild-watermellon-300-default: #ffa39e;
$wild-watermellon-200-default: #ffccc7;
$wild-watermellon-100-default: #fff1f0;

$wild-watermellon-1000: var(
  --wild-watermellon-1000,
  $wild-watermellon-1000-default
);
$wild-watermellon-900: var(
  --wild-watermellon-900,
  $wild-watermellon-900-default
);
$wild-watermellon-800: var(
  --wild-watermellon-800,
  $wild-watermellon-800-default
);
$wild-watermellon-700: var(
  --wild-watermellon-700,
  $wild-watermellon-700-default
);
$wild-watermellon-600: var(
  --wild-watermellon-600,
  $wild-watermellon-600-default
);
$wild-watermellon-500: var(
  --wild-watermellon-500,
  $wild-watermellon-500-default
);
$wild-watermellon-400: var(
  --wild-watermellon-400,
  $wild-watermellon-400-default
);
$wild-watermellon-300: var(
  --wild-watermellon-300,
  $wild-watermellon-300-default
);
$wild-watermellon-200: var(
  --wild-watermellon-200,
  $wild-watermellon-200-default
);
$wild-watermellon-100: var(
  --wild-watermellon-100,
  $wild-watermellon-100-default
);
$wild-watermellon: var(--wild-watermellon-600, $wild-watermellon-600-default);

$golden-rod-1000-default: #613400;
$golden-rod-900-default: #874d00;
$golden-rod-800-default: #ad6800;
$golden-rod-700-default: #d48806;
$golden-rod-600-default: #faad14;
$golden-rod-500-default: #ffc53d;
$golden-rod-400-default: #ffd666;
$golden-rod-300-default: #ffe58f;
$golden-rod-200-default: #fff1b8;
$golden-rod-100-default: #fffbe6;

$golden-rod-1000: var(--golden-rod-1000, $golden-rod-1000-default);
$golden-rod-900: var(--golden-rod-900, $golden-rod-900-default);
$golden-rod-800: var(--golden-rod-800, $golden-rod-800-default);
$golden-rod-700: var(--golden-rod-700, $golden-rod-700-default);
$golden-rod-600: var(--golden-rod-600, $golden-rod-600-default);
$golden-rod-500: var(--golden-rod-500, $golden-rod-500-default);
$golden-rod-400: var(--golden-rod-400, $golden-rod-400-default);
$golden-rod-300: var(--golden-rod-300, $golden-rod-300-default);
$golden-rod-200: var(--golden-rod-200, $golden-rod-200-default);
$golden-rod-100: var(--golden-rod-100, $golden-rod-100-default);
$golden-rod: var(--golden-rod-600, $golden-rod-600-default);

$sea-foam-1000-default: #002329;
$sea-foam-900-default: #00474f;
$sea-foam-800-default: #006d75;
$sea-foam-700-default: #08979c;
$sea-foam-600-default: #13c2c2;
$sea-foam-500-default: #36cfc9;
$sea-foam-400-default: #5cdbd3;
$sea-foam-300-default: #87e8de;
$sea-foam-200-default: #b5f5ec;
$sea-foam-100-default: #e6fffb;

$sea-foam-1000: var(--sea-foam-1000, $sea-foam-1000-default);
$sea-foam-900: var(--sea-foam-900, $sea-foam-900-default);
$sea-foam-800: var(--sea-foam-800, $sea-foam-800-default);
$sea-foam-700: var(--sea-foam-700, $sea-foam-700-default);
$sea-foam-600: var(--sea-foam-600, $sea-foam-600-default);
$sea-foam-500: var(--sea-foam-500, $sea-foam-500-default);
$sea-foam-400: var(--sea-foam-400, $sea-foam-400-default);
$sea-foam-300: var(--sea-foam-300, $sea-foam-300-default);
$sea-foam-200: var(--sea-foam-200, $sea-foam-200-default);
$sea-foam-100: var(--sea-foam-100, $sea-foam-100-default);
$sea-foam: var(--sea-foam-600, $sea-foam-600-default);

/* ----------- */
/* Brand Color */
/* ----------- */

$brand-normal: $pacific-primary-600;
$brand-selected: $pacific-primary-100;
$brand-click: $pacific-primary-500;
$brand-hover: $pacific-primary-700;

/* ---------------- */
/* Functional Color */
/* ---------------- */

$brand: $pacific-primary-600;
$link: $pacific-primary-600;
$success: $inch-worm-600;
$warning: $golden-rod-600;
$error: $wild-watermellon-600;
$info: $sea-foam-600;

/* ------------- */
/* Neutral Color */
/* ------------- */

$title: $wolf-800;
$primary-text: $wolf-800;
$secondary-text: $wolf-600;
$disabled: $wolf-500;
$border: $wolf-300;
$border-disabled: $wolf-200;
$dividers: $wolf-200;
$background: $wolf-100;
$table: $white;

/* ------------- */
/* Map with all colors and tones. To be used to create dynamically colors */
/* ------------- */

$colors-map: (
  "wolf": (
    "900": $wolf-900,
    "800": $wolf-800,
    "700": $wolf-700,
    "600": $wolf-600,
    "500": $wolf-500,
    "400": $wolf-400,
    "300": $wolf-300,
    "200": $wolf-200,
    "100": $wolf-100,
  ),
  "pacific-primary": (
    "default": $pacific-primary,
    "1000": $pacific-primary-1000,
    "900": $pacific-primary-900,
    "800": $pacific-primary-800,
    "700": $pacific-primary-700,
    "600": $pacific-primary-600,
    "500": $pacific-primary-500,
    "400": $pacific-primary-400,
    "300": $pacific-primary-300,
    "200": $pacific-primary-200,
    "100": $pacific-primary-100,
  ),
  "inch-worm": (
    "default": $inch-worm,
    "900": $inch-worm-900,
    "800": $inch-worm-800,
    "700": $inch-worm-700,
    "600": $inch-worm-600,
    "500": $inch-worm-500,
    "400": $inch-worm-400,
    "300": $inch-worm-300,
    "200": $inch-worm-200,
    "100": $inch-worm-100,
  ),
  "wild-watermellon": (
    "default": $wild-watermellon,
    "900": $wild-watermellon-900,
    "800": $wild-watermellon-800,
    "700": $wild-watermellon-700,
    "600": $wild-watermellon-600,
    "500": $wild-watermellon-500,
    "400": $wild-watermellon-400,
    "300": $wild-watermellon-300,
    "200": $wild-watermellon-200,
    "100": $wild-watermellon-100,
  ),
  "golden-rod": (
    "default": $golden-rod,
    "900": $golden-rod-900,
    "800": $golden-rod-800,
    "700": $golden-rod-700,
    "600": $golden-rod-600,
    "500": $golden-rod-500,
    "400": $golden-rod-400,
    "300": $golden-rod-300,
    "200": $golden-rod-200,
    "100": $golden-rod-100,
  ),
  "sea-foam": (
    "default": $sea-foam,
    "900": $sea-foam-900,
    "800": $sea-foam-800,
    "700": $sea-foam-700,
    "600": $sea-foam-600,
    "500": $sea-foam-500,
    "400": $sea-foam-400,
    "300": $sea-foam-300,
    "200": $sea-foam-200,
    "100": $sea-foam-100,
  ),
);
